.radio-item {
  background-color: $grey-toggle-off;
  color: #fff;
  cursor: pointer;
  display: block;
  float: left;
  font-size: em(11);
  padding: em(7) em(14);

  &:first-child {
    border-radius: 3px 0 0 3px;
  }
  &:last-child {
    border-radius: 0 3px 3px 0;
  }

  &[aria-checked="true"] {
    background-color: $grey-toggle-on;
  }
}
.radio-list {
  display: inline-block;
  margin-left: .5em;
  overflow: hidden;
  vertical-align: middle;
}