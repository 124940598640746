.profile-button {
  background: $blue-bg;
  border: 0;
  bottom: 0;
  box-sizing: content-box;
  font-size: em(13);
  height: 15px;
  left: 165px;
  line-height: 1em;
  padding: 6px 25px 6px 50px;
  position: absolute;

  @include respond($pc) {
    left: 230px;
    padding: 14px 40px 14px 65px;
  }

  &::before {
    background-image: url("/common/images/icon/icon-plus-blue.png");
    background-size: cover;
    content: "";
    display: inline-block;
    height: 1em;
    left: 25px;
    margin-right: 10px;
    margin-top: 1px;
    position: absolute;
    top: 6px;
    width: 1em;

    @include respond($pc) {
      left: 40px;
      top: 14px;
    }
  }
  &.open::before {
    background-image: url("/common/images/icon/icon-minus-blue.png");
    height: 2px;
    top: 11px;
    width: 13px;

    @include respond($pc) {
      top: 19px;
    }
  }
}
.profile-english {
  font-size: em(10);
  line-height: em(15,10);

  @include respond($pc) {
    font-size: em(12);
    line-height: em(24,12);
  }
}
.profile-image {
  left: 0;
  margin-right: 20px;
  position: absolute;
  top: 0;
  width: 150px;

  @include respond($pc) {
    width: 200px;
  }
}
.profile-job {
  display: block;
  font-size: em(13);

  small {
    display: block;
    margin: 5px 0;
    font-size: em(10,13);
    line-height: em(15,10);

    @include respond($pc) {
      font-size: 1em;
      margin: 0 0 5px;
    }
  }

  @include respond($pc) {
    font-size: em(14);
    line-height: em(24,14);
  }
}
.profile-name {
  display: block;
  font-size: em(16);
  font-weight: bold;
  line-height: 1em;

  @include respond($pc) {
    font-size: em(20);
    line-height: em(24,20);
  }
}
.profile-root {
  margin-bottom: 20px;
  min-height: 150px;
  padding-left: 165px;
  position: relative;

  @include respond($pc) {
    margin-bottom: 30px;
    min-height: 200px;
    padding-left: 230px;
  }
}
.profile-table {
  margin-bottom: 20px;

  &[aria-hidden="true"] {
    display: none;
  }
}
.profile-table-header {
  padding-left: 0 !important;
  width: 100px;

  @include respond($pc) {
    width: 200px;
  }
}
.profile-title {
  display: table;
  height: 150px;
  width: 100%;

  &.simple {
    border: 1px dashed $grey-underline;
    border-left: 0;
    border-right: 0;
    height: auto;
    padding: 15px 0;
  }

  @include respond($pc) {
    height: 200px;
  }
}
.profile-title-inner {
  display: table-cell;
  vertical-align: middle;
}