
.collapse-toggle {
  cursor: pointer;
  position: relative;

  & .collapse-ctrl {
    background: url("/common/images/icon/icon-plus.png") no-repeat;
    background-color: $grey-collapse;
    background-position: center center;
    background-size: 13px 13px;
    bottom: 0;
    content: " ";
    display: inline-block;
    height: 13px;
    margin: auto 9px;
    // padding: 15px;
    padding: 6px;
    position: absolute;
    top: 0;
    right: 0;
    width: 13px;
  }
  &.open .collapse-ctrl {
    background-image: url("/common/images/icon/icon-minus.png");
    background-size: 13px 2px;
    // height: 2px;
  }
  &.no-icon .collapse-ctrl {
    display: none;
  }
  &[data-collapse-color="blue"] {
    & .collapse-ctrl {
      background-image: url("/common/images/icon/icon-plus-blue.png");
    }
    &.open .collapse-ctrl {
      background-image: url("/common/images/icon/icon-minus-blue.png");
    }
  }

  &[data-collapse-when="sp"] {
    
    @include respond($pc) {
      & .collapse-ctrl {
        display: none;
      }
    }
  }
}
.collapse-wrap {
  height: 0px;
  overflow: hidden;
  
  -webkit-transition: height .2s ease-out;
  transition: height .2s ease-out;

  &[data-collapse-when="sp"] {
    @include respond($pc) {
      height: auto;// !important;
    }
  }
}

.collapse-button {
  box-sizing: border-box;
  display: inline-block;
  background-color: $grey-collapse;
  font-size: em(13);
  margin-bottom: 10px;
  padding: 7px;
  text-align: center;
  width: 90px;

  @include respond($pc) {
    padding: 14px;
    width: 120px;
  }
  
  .collapse-ctrl {
    background-image: url("/common/images/icon/icon-plus-small.png") !important;
    background-size: 8px 8px;
    height: 8px;
    margin: 0;
    padding: 0 7px 6px 0;
    position: static;
    vertical-align: bottom;
    width: 8px;

    @include respond($pc) {
      height: 14px;
      padding-bottom: 0;
      width: 14px;
    }
  }
  &.open .collapse-ctrl {
    background-image: url("/common/images/icon/icon-minus-small.png") !important;
    background-size: 8px 8px;

    @include respond($pc) {
      height: 14px;
      width: 14px;
    }
  }
}