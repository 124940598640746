.banner-grid-item {
  position: relative;

  @include respond($pc) {
    float: left;
    width: 50%;

    &:hover::before {
      opacity: .7;
    }
  }

  &::before {
    background: $blue-over;
    bottom: 0;
    content: " ";
    display: block;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;

    -webkit-transition: opacity .2s ease-in;
    transition: opacity .2s ease-in;
  }

  img {
    display: block;
    width: 100%;
  }
}
.banner-grid-list {
  margin-bottom: em(30);

  @include respond($pc) {
    margin-bottom: em(60);
  }
}