.hamburger-toggle {
  background-color: transparent;
  border: 0;
  bottom: 0;
  height: 21px;
  margin: auto;
  outline: none;
  padding: 0;
  right: em(25);
  top: 0;
  width: 26px;

  &.collapse-toggle {
    position: absolute;
  }
}
.hamburger-toggle-inner {
  background-color: $blue-light;
  display: block;
  height: 3px;
  margin-top: -1.5px;
  position: absolute;
  top: 50%;
  transition-duration: .1s;
  transition-property: transform;
  transition-timing-function: cubic-bezier(.55,.055,.675,.19);
  width: 26px;

  &::before, &::after {
    background-color: $blue-light;
    content: " ";
    display: block;
    height: 3px;
    position: absolute;
    width: 26px;
  }
  &::before {
    top: -8px;
    transition: top .1s .14s ease,opacity .1s ease;
  }
  &::after {
    bottom: -8px;
    transition: bottom .1s .14s ease,transform .1s cubic-bezier(.55,.055,.675,.19);
  }

  .open & {
    transform: rotate(45deg);
    transition-delay: .14s;
    transition-timing-function: cubic-bezier(.215,.61,.355,1);

    &::before {
      opacity: 0;
      top: 0;
      transition: top .1s ease,opacity .1s .14s ease;
    }
    &::after {
      bottom: 0;
      transform: rotate(-90deg);
      transition: bottom .1s ease,transform .1s .14s cubic-bezier(.215,.61,.355,1);
    }
  }
}
.header-search {
  box-sizing: border-box;
  padding: em(32) em(134) em(32) em(32);
  position: relative;
  text-align: center;

  .search-input, input.gsc-input {
    background: #fff !important;
    border: 1px solid $blue-border;
    color: rgba(0,0,0,.5);
  }
  .search-input-wrap::after {
    background-image: url("/common/images/icon/icon-search-grey.png");
  }
}
.header-search-border {
  border: 1px solid $blue-light;
}
.header-search-close {
  background: transparent;
  border: 0;
  bottom: 0;
  cursor: pointer;
  display: inline-block;
  font-size: em(14);
  height: em(20, 14);
  margin: auto;
  position: absolute;
  right: 30px;
  top: 0;

  .icon {
    margin-left: em(5);
  }
}
.header-search-form {
  // margin: 0 auto;
  // max-width: em(680);
}
.header-search-wrap {
  background: #fff;
  box-sizing: border-box;
  height: 0;
  left: 0;
  margin: 0 auto;
  max-width: 100%;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  width: 960px;
  z-index: 3;
}
.header-masthead {
  background: #fff;
  border-bottom: 2px solid $blue-light;
  padding: em(10) 0;
  position: relative;
  text-align: center;
  z-index: 50;

  @include respond($pc) {
    border-bottom: 0;
    padding: em(22) 0;
    // position: static;
    text-align: left;
  }
}
.header-nav {
  // background-color: $blue-light;
  display: none;
  box-sizing: border-box;
  height: 0%;
  left: 0;
  overflow: scroll;
  padding-top: em(61);
  position: absolute;
  right: 0;
  top: 0;
  z-index: 49;

  -webkit-overflow-scrolling: touch;
  -webkit-transition: height .2s ease-out;
  transition: height .2s ease-out;

  // &.open {
  //   display: block;
  // }
  &[aria-expanded="true"] {
    display: block;
  }

  // &[aria-hidden="false"] {
  //   height: 100%;
  // }

  @include respond($pc) {
    display: block;
    border-bottom: 3px solid $blue-light;
    padding-top: 0;
    position: relative;
    z-index: 51;

    &.collapse-wrap {
      overflow: visible;
    }
  }
}
.header-nav-item {
  background: #fff;
  border-bottom: 1px solid $blue-light;
  text-align: left;

  @include respond($pc) {
    border-bottom: 0;
    float: left;
    margin: 0;
    width: 14.28571%;
  }
}
.header-nav-link-text {
  @include respond($pc) {
    border-left: 1px solid $grey-border;
    display: block;

    .header-nav-item:last-child & {
      border-right: 1px solid $grey-border;
    }
  }
}
.header-nav-link {
  cursor: pointer;
  display: block;
  font-size: em(13);
  padding: em(15,13);

  @include respond($pc) {
    font-size: em(15);
    padding: em(10,15) 0;
    text-align: center;

    &.current, &:hover {
      background-color: $blue-bg;
      // font-weight: bold;
    }
  }

  &.collapse-toggle, &.collapse-toggle[data-collapse-when] {

    @include respond($pc) {
      &::after {
        border: 6px solid transparent;
        border-top-color: $blue-light;
        content: " ";
        display: none;
        height: 0;
        left: 0;
        margin: 3px auto 0;
        position: absolute;
        right: 0;
        top: 100%;
        width: 0;
        z-index: 52;
      }
      .header-nav-item.is-hover &::after {
        display: block;
      }
    }
  }

  &::before {
    border: em(5, 13) solid transparent;
    border-left-color: $blue-light;
    content: " ";
    display: inline-block;
    vertical-align: middle;

    @include respond($pc) {
      display: none;
    }
  }

  &, &:link, &:visited {
    color: #000;

    &.current {
      color: $blue-light;
    }
  }
  &:hover {
    color: $blue-light;
  }
}
.header-nav-list {
  margin: 0 auto 0;
  max-width: 960px;

  @include respond($pc) {
    margin-top: -10px;
  }
}
.header-nav-search {
  background-color: #fff;
  padding: em(15);
}
.masthead-ctrl-item {
  border-right: 1px solid $grey-border;
  display: inline-block;
  // display: block;
  // float: left;
  line-height: em(13);
  margin-top: em(10);
  padding: 0 em(15);
  vertical-align: middle;

  &:last-child {
    border-right: 0;
    padding-right: 0;
  }

  .masthead-ctrl-item {
    margin-top: 0;

    &:last-child {
      padding-right: 0;
    }
  }

  &.group {
    display: block;
    padding: 0;
    text-align: right;

    @include respond(em(900)) {
      display: inline-block;
      text-align: left;
    }
  }

  @include respond($pc) {
    text-align: left;
  }
}
.masthead-ctrl-label {
  // color: $blue-light;
  font-size: em(13);
}
.masthead-ctrl-link {
  font-size: em(13);
  line-height: 1em;
}
.masthead-ctrl-list {
  display: none;
  float: right;
  margin-top: em(-15);
  max-width: calc(100% - 161px);
  overflow: hidden;

  // @include respond(em(900)) {
  //   margin-top: 0;
  // }

  @include respond($pc) {
    display: block;
    margin-top: 0;
    // max-width: 72%;
    text-align: right;
  }
}
.masthead-logo {
  background: url("/common/images/logo.png") no-repeat;
  background-size: cover;
  display: inline-block;
  height: 37px;
  width: 121px;

  @include respond($pc) {
    height: 49px;
    width: 161px;
  }
}