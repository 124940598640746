.ofcarousel-control {
  border: 0;
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 10px 17.5px;
  bottom: 0;
  cursor: pointer;
  height: 37.5px;
  margin: auto;
  outline: none;
  padding: 0;
  position: absolute;
  top: 0;
  width: 30px;

  &[aria-disabled="true"] {
    cursor: default;

    &.right {
      
    }
    &.left {
      
    }
  }

  &.right {
    background-image: url("/common/images/chevron/chevron-right-blue.png");
    right: -10px;
  }
  &.left {
    background-image: url("/common/images/chevron/chevron-left-blue.png");
    left: -10px;
  }
}
.ofcarousel-inner {
  overflow: hidden;
  position: relative;
}
.ofcarousel-item {
  box-sizing: border-box;
  float: left;
  padding: 0 10px;

  [data-size="2"] & {
    width: 50%;
  }
  [data-size="3"] & {
    width: 33.33333%;
  }

  @include respond($pc) {
    .list[data-size] & {
      margin-bottom: 10px;
      padding: 0;
      width: auto;
    }
  }
}
.ofcarousel-list {
  font-size: 1em;
  left: 0%;
  margin-bottom: 0;
  overflow: hidden;
  position: relative;
  width: 500%;

  -webkit-transition: left .2s ease-out;
  transition: left .2s ease-out;

  @include respond($pc) {
    margin: 0 -10px;
    position: static;
    width: auto;

    &.list {
      margin: 0;

      .imgcap-panel {
        height: 92px;
      }
      .imgcap-img {
        height: 90px;
      }
      .imgcap-panel {
        padding-left: 105px;
      }
    }
  }

  &[data-size="2"] {
    width: 200%;
  }
  &[data-size="3"] {
    width: 300%;
  }

  &[data-size] {
    @include respond($pc) {
      width: auto;
    }
  }
}
.ofcarousel-main {
  padding: 0 em(15);
  position: relative;

  @include respond($pc) {
    padding: em(15) 0;
  }
}
.ofcarousel-nav {
  list-style-type: none;
  padding-top: 10px;
  text-align: center;

  @include respond($pc) {
    display: none;
  }
}
.ofcarousel-nav-item {
  cursor: pointer;
  display: inline-block;
  height: 14px;
  margin: 0 8px;
  position: relative;
  vertical-align: middle;
  width: 14px;

  &::after {
    border: 5px solid $blue-light;
    border-radius: 50px;
    bottom: 0;
    content: "";
    display: block;
    height: 0px;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 0px;

    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out;
  }
  &.current::after {
    border-width: 2px;
    height: 10px;
    width: 10px;
  }
}
.ofcarousel-wrap {
  margin-bottom: em(40);

  @include respond($pc) {
    margin-bottom: em(60);
  }
}