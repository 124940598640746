.table-wrap {
  overflow-x: auto;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  &::-webkit-scrollbar:horizontal {
    height: 11px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 4px solid white; /* should match background, can't be transparent */
    background-color: rgba(0, 0, 0, .5);
  }
  &::-webkit-scrollbar-track { 
    background-color: #fff; 
    border-radius: 8px; 
  } 

  .table {
    min-width: 768px;

    @include respond($pc) {
      min-width: 0;
    }
  }

  // &.large .table {
  //   min-width: 950px;
  // }
  // &.med .table {
  //   min-width: 768px;
  // }
  // &.small .table {
  //   min-width: 400px;
  // }
  // &.large .table, &.med .table, &.small .table {
  //   @include respond($pc) {
  //     min-width: 0;
  //   }
  // }
  
}
.table {
  border-collapse: collapse;
  font-size: em(13);
  line-height: em(18,13);
  text-align: left;
  width: 100%;

  @include respond($pc) {
    font-size: em(14);
    line-height: em(24,14);
  }

  & > tbody > tr > th {
    background: $grey-nav;
    font-weight: normal;
    min-width: 70px;
  }
  &:not(.vtable) > tbody > tr > td:nth-child(2) {
    background: $grey-nav-light;
  }
  &:not(.vtable) > tbody > tr > td:last-child {
   background: #fff;
  }
  &:not(.vtable) > tbody > tr > td:first-child {
    background: $grey-nav-light;
  }
  & > tbody > tr > td, & > tbody > tr > th {
    background-clip: padding-box !important;
    border: 1px solid $grey-underline;
    padding: em(10, 13);
    position: relative;

    @include respond($pc) {
      border-width: 2px;
      padding: em(10, 14);
    }
  }
  .buttoned {
    @include respond($pc) {
      min-height: em(44,14);
      padding-right: em(146,14);
    }
  }
  &.dashed {
    & > tbody > tr > td, & > tbody > tr > th {
      border-style: dashed;
      border-width: 1px;
    }
  }
  &.simple {
    & > tbody > tr > th {
      font-weight: bold;
      vertical-align: top;
    }
    & > tbody > tr > th, & > tbody > tr > td {
      background: transparent;
      border-left: 0;
      border-right: 0;
    }
    & > tbody > tr > th {
      padding-right: 15px;
    }
    & > tbody > tr > td {
      padding-left: 15px;
    }
  }
}
.table-button {
  background: $grey-nav;
  display: inline-block;
  margin-top: em(10, 13);
  padding: 5px 10px 5px 20px;
  text-decoration: none !important;
  vertical-align: middle;

  @include respond($pc) {
    bottom: 10px;
    padding: 10px 20px 10px 30px;
    position: absolute;
    right: 10px;
  }
}
.table-liner {
  &.large {
    width: 200px;

    @include respond($pc) {
      width: 400px;
    }
  }
}
.empty-row {
  border: 0 !important;
  background: #fff !important;
}
.nested-table-head {
  @include respond($pc) {
    width: 175px;
  }
}