.footer-copyright {
  background-color: $blue-over;
  padding: em(20) 0;

  @include respond($pc) {
    padding: em(15) 0;
  }
}
.footer-copyright-text {
  color: #fff;
  font-size: em(10);
  text-align: center;

  @include respond($pc) {
    font-size: em(11);
  }
}
.footer-inline-item {
  display: inline-block;
  font-size: em(11);
  line-height: 3em;

  @include respond($pc) {
    line-height: 1em;
  }
}
.footer-inline-link {
  border-left: 1px solid #000;
  line-height: 1em;
  padding: 0 8px;

  &:hover {
    opacity: .6;
  }
  &:link, &:visited {
    color: #000;
  }
  .footer-inline-item:last-child & {
    border-right: 1px solid #000;
  }
}
.footer-inline-list {
  padding: 55px 0 20px;
  text-align: center;
}
.footer-link {
  border-bottom: 1px solid #fff;
  display: block;
  font-size: em(13);
  padding: em(15,13);

  &::before {
    border: em(6, 13) solid transparent;
    border-left-color: $blue-light;
    border-bottom-width: em(5, 13);
    border-top-width: em(5, 13);
    content: " ";
    display: inline-block;
    height: 0;
    margin-right: em(5, 13);
    vertical-align: middle;
  }
  &:hover {
    color: $blue-light;
    opacity: .6;
  }

  @include respond($pc) {
    border-bottom: 0;
    padding: em(6, 13) 0;

    &, &:link, &:visited {
      color: #000;
    }

    &::before {
      display: none;
    }
  }
}
.footer-link-col-item {

  @include respond($pc) {
    float: left;
    width: 25%;
  }
}
.footer-link-col-list {
  margin: 0 em(-15);

  @include respond($pc) {
    margin: 0;
    padding-top: em(10);
  }
}
.footer-link-item {
  border-bottom: 1px solid $grey-footer-border;

  @include respond($pc) {
    border: 0;
    padding-left: em(10);
  }

  &:first-child {
    border-top: 1px solid $grey-footer-border;

    @include respond($pc) {
      border-top: 0;
    }
  }
  &:last-child {
    border-bottom: 0;
  }

  & > .footer-link {
    border-bottom: 0;
    padding-left: em(30, 13);
    // padding-right: em(15);

    @include respond($pc) {
      padding-left: 0;
      // padding-right: 0;
    }
  }
  .footer-link-item > .footer-link {
    padding-left: em(45,13);

    @include respond($pc) {
      padding-left: 0;
    }
  }

  // & > .footer-link-list {
  //   margin-left: em(15);
  //   margin-right: em(15);
  // }
}
.footer-link-list {
  background-color: #fff;
  // padding: 0 em(15);

  @include respond($pc) {
    background-color: transparent;
    padding: 0;
  }
}
.footer-links {
  background-color: $blue-bg;
}
.footer-list-title {
  font-size: em(13);
  // padding: em(15,13);

  &, &:link, &:visited {
    color: #000;
  }
  &:hover {
    color: $blue-light;
  }

  // &::before {
  //   border-left-color: #fff;
  // }

  @include respond($pc) {
    font-size: em(15);
    font-weight: bold;
    margin-bottom: 0;
    margin-top: em(15, 15);
    padding: em(15, 15) 0;

    &::before {
      display: none;
    }

    & + .footer-list-title {
      margin-top: 0;
    }
  }
}
.footer-top-link {
  background: $blue-light;
  border-radius: 100px;
  bottom: em(34);
  display: none;
  height: em(30);
  position: fixed;
  right: em(15);
  width: em(30);

  @include respond($pc) {
    bottom: em(15);
    height: em(40);
    right: em(20);
    width: em(40);
  }
  @include respond($dt) {
    margin-right: em(-480);
    right: 50%;
  }

  -moz-box-shadow: 1px 1px 5px 1px rgba(0,0,0,.25);
  -webkit-box-shadow: 1px 1px 5px 1px rgba(0,0,0,.25);
  box-shadow: 1px 1px 5px 1px rgba(0,0,0,.25);

  &::before {
    border: em(5) solid transparent;
    border-bottom-color: #fff;
    border-left-width: em(4);
    border-right-width: em(4);
    bottom: 0;
    content: " ";
    height: 0;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: em(-7);
    width: 0;

    @include respond($pc) {
      border: em(7) solid transparent;
      border-bottom-color: #fff;
      border-left-width: em(6);
      border-right-width: em(6);
      top: em(-10);
    }
  }
}