.icon {
  background-repeat: no-repeat;
  background-size: cover;
  display: inline-block;

  &.bottom {
    vertical-align: bottom;
  }
  &.middle {
    vertical-align: middle;
  }
  &.after {
    position: absolute;
    // top: .5em;
    right: -16px;
    top: 0;
    bottom: 0;
    margin: auto;
  }
}
.icon-building {
  background-image: url("/common/images/icon/icon-building.png");
  height: 25px;
  width: 25px;

  @include respond($dt) {
    height: 50px;
    width: 50px;
  }
}
.icon-card {
  background-image: url("/common/images/icon/icon-card.png");
  height: 25px;
  width: 25px;

  @include respond($dt) {
    height: 50px;
    width: 50px;

    &.small {
      height: 25px;
      width: 25px;
    }
  }
}
.icon-chip {
  background-image: url("/common/images/icon/icon-chip.png");
  height: 25px;
  width: 25px;

  @include respond($dt) {
    height: 50px;
    width: 50px;

    &.small {
      height: 25px;
      width: 25px;
    }
  }
}
.icon-close {
  background-image: url("/common/images/icon/icon-close.png");
  height: 1em;
  width: 1em;
  vertical-align: middle;

  &.grey {
    background-image: url("/common/images/icon/icon-close-grey.png");
  }
}
.icon-cloud {
  background-image: url("/common/images/icon/icon-cloud.png");
  height: 25px;
  width: 25px;

  @include respond($dt) {
    height: 50px;
    width: 50px;

    &.small {
      height: 25px;
      width: 25px;
    }
  }
}
.icon-download {
  background-image: url("/common/images/icon/icon-download.png");
  height: 15px;
  width: 16px;
}
.icon-external {
  background-image: url("/common/images/icon/icon-external.png");
  height: 12px;
  width: 17px;
}
.icon-house {
  background-image: url("/common/images/icon/icon-house.png");
  height: 25px;
  width: 25px;

  @include respond($dt) {
    height: 50px;
    width: 50px;
  }
}
.icon-iot {
  background-image: url("/common/images/icon/icon-iot.png");
  height: 25px;
  width: 25px;

  @include respond($dt) {
    height: 50px;
    width: 50px;

    &.small {
      height: 25px;
      width: 25px;
    }
  }
}
.icon-pdf {
  background-image: url("/common/images/icon/icon-pdf.png");
  height: 15.5px;
  vertical-align: bottom;
  width: 13px;
}
.icon-rss {
  background-image: url("/common/images/icon/icon-rss.png");
  height: 14px;
  width: 14px;
}
.icon-search {
  background-image: url("/common/images/icon/icon-search.png");
  height: 16px;
  width: 17px;
  vertical-align: middle;

  &.grey {
    background-image: url("/common/images/icon/icon-search-grey.png");
  }
}
.icon-shop {
  background-image: url("/common/images/icon/icon-shop.png");
  height: 25px;
  width: 25px;

  &.small {
    height: 25px;
    width: 25px;
  }

  @include respond($dt) {
    height: 50px;
    width: 50px;
  }
}