.imgcap-img {
  bottom: 0;
  display: block;
  left: 0;
  height: 108px;
  position: absolute;
  top: 0;
}
.imgcap-panel {
  border: 1px solid $grey-border;
  box-sizing: border-box;
  display: block;
  font-size: em(13);
  height: 110px;
  line-height: em(18,13);
  padding: 10px 15px 10px 123px;
  position: relative;

  @include respond($pc) {
    font-size: em(14);
    line-height: em(20,14);
  }

  .caret-link {
    display: block;
    text-decoration: underline;

    @include respond($pc) {
      max-height: em(60, 14);
      overflow: hidden;
    }
  }

  // &:hover {
  //   .caret-link {}
  // }
}
a.imgcap-panel:hover {
  @include respond($pc) {
    opacity: .7;
  }
}
.imgcap-tag {
  background-color: $blue-light;
  border-radius: 3px;
  color: #fff;
  display: inline-block;
  font-size: em(11);
  line-height: 1em;
  margin-bottom: 5px;
  min-width: em(55,11);
  padding: 2px;
  text-align: center;
}