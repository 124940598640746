ol, ul {
	list-style: none;

  &.inline-list {
    li {
      display: inline-block;
    }
  }
}
.button-item {
  box-sizing: border-box;
  display: inline-block;
  font-size: 13px;
  line-height: em(18, 13);
  margin-bottom: 20px;
  padding: 0 10px;
  width: 50%;

  @include respond($pc) {
    font-size: 14px;
  }
}
.button-list {
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -webkit-justify-content: center;
  justify-content: center;
  flex-wrap: wrap;
  font-size: 0;
  margin: 0 -10px;
  text-align: center;

  .button-link {
    box-sizing: border-box;
    height: 100%;
  }
}

.logo-item {
  display: inline-block;
  vertical-align: middle;

  @include respond($pc) {
    padding: 0 em(15);
  }
}
.logo-list {
  margin-bottom: em(20);
  text-align: center;

  @include respond($pc) {
    margin-bottom: em(40);
  }
}
.list {
  font-size: em(13);
  line-height: em(25,13);
  // margin-bottom: em(20,13);

  @include respond($pc) {
    font-size: em(14);
    line-height: em(28,14);
    // margin-bottom: em(40,14);
  }

  &.large {
    font-size: em(15);
    line-height: em(28,15);
    margin-bottom: em(20,15);

    @include respond($pc) {
      font-size: em(16);
      line-height: em(30,16);
      margin-bottom: em(40,16);
    }
  }
  &.small {
    font-size: em(10);
    line-height: em(18,10);
    margin-bottom: em(20,10);

    @include respond($pc) {
      font-size: em(12);
      line-height: em(22,12);
      margin-bottom: em(40,12);
    }
  }
  &.margined {
    & > li {
      margin-bottom: 5px;
      
      &:last-child {
        margin-bottom: 0;
      }
    }
    .text-box & {
      margin-bottom: 0;
    }
  }
  &.spaced {
    & > li {
      // margin-bottom: 25px;

      @include respond($pc) {
        // margin-bottom: 30px;
      }
    }
  }
}
.dotted {
  padding-left: 10px;
  position: relative;

  li::before {
    content: "・";
    left: 0;
    position: absolute;
  }
}
.numbered {
  counter-reset: numbers;

  li {
    counter-increment: numbers;
    padding-left: 20px;

    &::before {
      content: counter(numbers) ". ";
      display: inline-block;
      margin-left: -20px;
      width: 20px;
    }
  }
}
.number-list {
  font-size: em(13);
  line-height: em(18,13);
  padding-left: 25px;
  position: relative;

  @include respond($pc) {
    font-size: em(14);
    line-height: em(24,14);
  }

  & > li {
    margin-bottom: 20px;
  }

  .number {
    left: 0;
    position: absolute;
  }
}
.number-plate {
  background: $grey-nav-light;
  font-size: em(22);
  line-height: 1em;
  margin: 0 auto 20px;
  padding: 16px;
  text-align: center;
  width: 180px;

  @include respond($pc) {
    font-size: em(34);
    margin-bottom: 25px;
    padding: 20px;
    width: 260px;
  }

  small {
    display: block;
    font-size: em(10,22);
    line-height: 1em;
    margin-bottom: 10px;

    @include respond($pc) {
      font-size: em(14,34);
    }
  }
}
.qa-item {
  border-bottom: 2px solid #ddd;
  padding: 15px 0;
}
.qa-link {
  display: block;
  margin-top: 10px;
  text-decoration: none;
}
.qa-list {
  border-top: 2px solid #ddd;
}
.qa-part {
  padding-left: 20px;
  position: relative;

  @include respond($pc) {
    padding-left: 35px;
  }

  &::before {
    color: $blue-light;
    font-weight: bold;
    left: 0;
    position: absolute;
  }
}
.qa-a {
  font-size: em(13);
  line-height: em(18,13);

  @include respond($pc) {
    font-size: em(14);
    line-height: em(24,14);
  }

  &::before {
    content: "A.";

    @include respond($pc) {
      font-size: em(20,14);
      left: 2px;
    }
  }
}
.qa-q {
  font-size: em(14);
  font-weight: bold;
  line-height: em(24,14);
  margin-bottom: 10px;

  @include respond($pc) {
    font-size: em(16);
    line-height: em(24,16);
    margin-bottom: 15px;
  }

  &::before {
    content: "Q.";
    font-size: em(15,14);

    @include respond($pc) {
      font-size: em(24,16);
    }
  }
}