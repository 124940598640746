@-ms-keyframes fade {
  0% {
    display: none;
    height: auto;
    opacity: 0;
    padding-top: 0px;
  }
  1% {
    display: block;
    opacity: 0;
  }
  100% {
    display: block;
    height: auto;
    opacity: 1;
    padding-top: 10px;
  }
}
@-webkit-keyframes fade {
  0% {
    display: none;
    height: auto;
    opacity: 0;
    padding-top: 0px;
  }
  1% {
    display: block;
    opacity: 0;
  }
  100% {
    display: block;
    height: auto;
    opacity: 1;
    padding-top: 10px;
  }
}
@keyframes fade {
  0% {
    display: none;
    height: auto;
    opacity: 0;
    padding-top: 0px;
  }
  1% {
    display: block;
    opacity: 0;
  }
  100% {
    display: block;
    height: auto;
    opacity: 1;
    padding-top: 10px;
  }
}
@-ms-keyframes fadeout {
  0% {
    // display: block;
    height: 0px;
    opacity: 1;
    padding-top: 0px;
  }
  99% {
    display: block;
    opacity: 0;
  }
  100% {
    display: none;
    height: 0px;
    opacity: 0;
    padding-top: 0px;
  }
}
@-webkit-keyframes fadeout {
  0% {
    // display: block;
    height: 0px;
    opacity: 1;
    padding-top: 0px;
  }
  99% {
    display: block;
    opacity: 0;
  }
  100% {
    display: none;
    height: 0px;
    opacity: 0;
    padding-top: 0px;
  }
}
@keyframes fadeout {
  0% {
    // display: block;
    height: 0px;
    opacity: 1;
    padding-top: 0px;
  }
  99% {
    display: block;
    opacity: 0;
  }
  100% {
    display: none;
    height: 0px;
    opacity: 0;
    padding-top: 0px;
  }
}


.mdd {
  left: 0;
  max-width: 100%;
  right: 0;
  top: 100%;
  width: 960px;
  z-index: 50;

  @include respond($pc) {
    // display: none;
    margin: 0 auto;
    padding-top: 10px;
    position: absolute;
    
    -ms-animation: fadeout .2s forwards;
    -webkit-animation: fadeout .2s forwards;
    animation: fadeout .2s forwards;

    .mdd-anchor.is-hover & {
      -ms-animation: fade .2s normal forwards;
      -webkit-animation: fade .2s normal forwards;
      animation: fade .2s normal forwards;
      z-index: 51;
    }
    .header-nav-list.is-hover .mdd-anchor:not(.is-hover) & {
      -ms-animation-delay: .15s;
      -webkit-animation-delay: .15s;
      animation-delay: .15s;
    }
  }
}
.mdd-banner-item {
  margin-bottom: 5px;

  a, img {
    display: block;

    &:hover {
      opacity: .7;
    }
  }
}
.mdd-banner-list {
  display: none;

  @include respond($pc) {
    display: block;
    float: right;
    margin: 20px 5px 0 0;
  }
}
.mdd-col {

  @include respond($pc) {
    box-sizing: border-box;
    float: left;
    margin-right: 30px;
    padding: em(20) em(5);
    // width: 25%;
  }
}
.mdd-col-item {
  background: $grey-nav;
  border-bottom: 1px solid #fff;

  .mdd-col:nth-last-child(2) > &:last-child {
    border-bottom: 0;
  }
  
  @include respond($pc) {
    background: transparent;
    // border-bottom: 2px solid $grey-nav;
    border-bottom: 0;

    & + .mdd-col-item .mdd-link {
      padding-top: 7px;
    }
  }
}
.mdd-inner {
  margin: 0 auto;
  max-width: 970px;
}
.mdd-pad {
  background: #fff;
  overflow: hidden;

  @include respond($pc) {
    border: 1px solid $blue-light;
    padding: 10px 25px;
  
    -webkit-box-shadow: 0 3px 7px 0 rgba(0,0,0,.25);
    box-shadow: 0 3px 7px 0 rgba(0,0,0,.25);
  }
}
.mdd-sub-item {
  background: #fff;
  border-bottom: 1px solid $grey-nav;

  @include respond($pc) {
    background: transparent;
    border-bottom-color: transparent;

    &:first-child .mdd-link {
      margin-top: 5px;
      padding-top: 0;
    }
    &:last-child .mdd-link {
      padding-bottom: 0;
    }
    .mdd-link {
      padding-top: 7px;
      padding-bottom: 7px;
    }
  }

  &:last-child {
    border-bottom-color: transparent;
  }
}
.mdd-sub-list {
  @include respond($pc) {
    border-left: 1px solid $blue-rule;
    margin-left: 15px;
    margin-bottom: 13px;
  }
}
.mdd-link {
  display: block;
  font-size: em(13);
  outline: none;
  padding: em(15, 13) 0 em(15, 13) em(25, 13);

  .mdd-sub-item > & {
    padding-left: em(35, 13);
  }

  &::before {
    border: em(5, 13) solid transparent;
    border-left-color: $blue-light;
    content: " ";
    display: inline-block;
    vertical-align: middle;

    @include respond($pc) {
      display: none;
    }
  }

  &.has-icon {
    .icon {
      position: relative;
      top: -1px;
    }
  }

  @include respond($pc) {
    font-size: em(15);
    font-weight: bold;
    padding: em(10, 15) 0 em(10, 15) em(10, 15);

    .mdd-col-item > & {
       // border-bottom: 2px solid $grey-nav;
    }
    .mdd-sub-item > & {
      // padding-left: em(20, 15);
      font-weight: normal;
      padding-left: 10px;
    }
    &.current, &:hover {
      // background-color: $grey-nav;
      color: $blue-light;
    }
    &.has-icon {
      height: 1em;
      line-height: 1em;
    }
  }
}