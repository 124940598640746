.tab-content-list {
  margin-bottom: 20px;
}
.tab-item {
  background: $grey-nav;
  border-left: 3px solid #fff;
  border-right: 3px solid #fff;
  box-sizing: border-box;
  color: $blue-light;
  cursor: pointer;
  font-size: em(13);
  float: left;
  height: 56px; //display flex not working in android 4.4
  padding: 15px;
  text-align: center;
  word-break: break-all;

  @include respond($pc) {
    font-size: em(16);
    height: auto;

    &:hover {
      background: rgba(232,237,240,0.6);
    }
  }

  &.current {
    background: $blue-light;
    color: #fff;
    position: relative;

    @include respond($pc) {
      &:hover {
        opacity: .7;
      }
    }

    &::after {
      border: 5px solid transparent;
      border-top-color: $blue-light;
      bottom: -10px;
      content: " ";
      height: 0;
      left: 0;
      margin: auto;
      position: absolute;
      right: 0;
      // top: 100%;
      width: 0;

      @include respond($pc) {
        border-width: 7px 11px;
      }
    }
  }

  &:nth-last-child(3):first-child, &:nth-last-child(3):first-child ~ .tab-item {
    width: 33.3333%;
  }
  &:nth-last-child(4):first-child, &:nth-last-child(4):first-child ~ .tab-item {
    width: 25%;
  }
}
.tab-inner {

  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);

  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;

  .hidden & {
    opacity: 0;
    -webkit-transform: scale(.9);
    transform: scale(.9);
  }
}
.tab-list {
  // display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  margin: 0 -6px 20px;
}
.tab-content-item {

  &[aria-hidden="true"] {
    display: none;
  }
}