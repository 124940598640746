@import "_helpers/colors";
@import "_helpers/constants";
//@import "_helpers/sprite";
@import "_helpers/media";
@import "_helpers/retina-sprite";
@import "_helpers/utils";

@import "_base/reset";

@import "_base/breadcrumb";
@import "_base/clearfix";
@import "_base/footer";
@import "_base/form";
@import "_base/header";
@import "_base/headers";
@import "_base/icon";
@import "_base/images";
@import "_base/input";
@import "_base/layout";
@import "_base/links";
@import "_base/list";
@import "_base/show-hide";
@import "_base/side-nav";
@import "_base/table";
@import "_base/typography";

@import "_widgets/overflow-carousel";

@import "_widgets/banner-grid";
@import "_widgets/collapse";
@import "_widgets/info-display";
@import "_widgets/imgcap-panel";
@import "_widgets/link-grid";
@import "_widgets/megadrop";
@import "_widgets/movie-banner";
@import "_widgets/news";
@import "_widgets/profile";
@import "_widgets/radios";
@import "_widgets/tabs";
@import "_widgets/timeline";

.block-s {
  margin-bottom: 10px;

  @include respond($pc) {
    margin-bottom: 20px;
  }
}
.block-m {
  margin-bottom: 20px;

  @include respond($pc) {
    margin-bottom: 30px;
  }
}
.block-l {
  margin-bottom: 40px;
}
.block-xl {
  margin-bottom: 60px;
}