.error-message {
  color: $red-over;
  font-size: em(13);
  line-height: em(18,13);
  margin-top: 10px;

  @include respond($pc) {
    font-size: em(14);
    line-height: em(24,14);
  }

  & + .form-field, & + .form-field-pair-wrap {
    margin-top: 10px;
  }
}
.form-cta {
  margin-bottom: em(30);
  text-align: center;

  @include respond($pc) {
    margin-bottom: em(60);
  }
}
.form-label {
  display: block;

  @include respond($pc) {
    margin-bottom: em(10,14);

    &:last-child {
      margin-bottom: 0;
    }
  }
}
.form-field {
  border: 1px solid $grey-dash;
  box-sizing: border-box;
  display: block;
  font-size: 1em;
  padding: 4px;
  width: 100%;

  .form-field + &, & + .form-field-pair-wrap {
    margin-top: em(10,14);
  }
  &.dashed {
    display: inline-block;
    margin-top: 0;
    width: 25%;
  }
  &.error {
    border: 1px solid $red;
    background-color: $red-bg;
  }
}
textarea.form-field {
  height: em(26*5);
}
.form-field-pair {
  padding-right: em(80,14);

  .en & {
    padding-right: em(90,14);
  }
}
.form-field-pair-wrap {
  position: relative;

  &::after {
    top: 0px;
    content: "（確認用）";
    display: block;
    position: absolute;
    right: em(-80,14);

    .en & {
      content: "(Confirm)";
    }
  }
}
.form-placeholder {
  color: #888;
  display: block;
  font-size: em(12,14);
  font-weight: normal;
  white-space: nowrap;

  &.inline {
    display: inline-block;
    padding-left: 5px;
    vertical-align: middle;
  }
}
.form-security {
  border: 2px solid $grey-nav;
  margin-bottom: 50px;
  padding: 20px;
  position: relative;

  @include respond($pc) {
    font-size: em(13,14);
    margin-bottom: 100px;
    padding: 20px 210px 20px 40px;
  }

  .reading {
    margin: 0;
  }

  img {
    display: block;
    float: none !important;
    margin: em(14) auto 0;
    width: 134px;

    @include respond($pc) {
      bottom: 0;
      margin: auto;
      position: absolute;
      right: 40px;
      top: 0;
    }
  }
}
.form-submit {
  background-color: $grey-nav;
  box-sizing: border-box;
  border: 0;
  cursor: pointer;
  font-size: em(15);
  max-width: 100%;
  padding: em(20,15) 0;
  text-align: center;
  width: em(350,15);

  &::before {
    border: 6px solid transparent;
    border-left-color: $blue-light;
    content: " ";
    display: inline-block;
    margin-right: 3px;
    vertical-align: middle;
  }

  &:hover {
    background-color: $grey-nav-light;
  }

  &.inline {
    margin-bottom: 10px;
  }

  @include respond($pc) {
    &.inline {
      max-width: 48%;
      margin: 0 1%;
    }
  }
}

.form-table-data {
  border-bottom: 1px solid $grey-dash;
  padding: em(12,14) 0;

  @include respond($pc) {
    border-bottom-width: 2px;
    display: table-cell;
    padding: em(16,14);
    vertical-align: top;
  }
  &.table-head {
    border-bottom: 0;
    font-weight: bold;
    padding-bottom: 0;

    @include respond($pc) {
      background: $blue-bg;
      border-bottom: 2px solid $grey-dash;
      width: em(250,14);
    }
  }
  .required {

    &::after {
      background: $red;
      border-radius: 2px;
      color: #fff;
      content: "必須";
      display: inline-block;
      font-size: 10px;
      font-weight: normal;
      line-height: 1em;
      margin-left: 10px;
      padding: 3px 5px;

      .en & {
        content: "Required";
      }
    }    
  }
}
.form-table-row {

  @include respond($pc) {
    display: table-row;
  }
}
.form-table {
  border-top: 1px solid $grey-dash;
  font-size: em(14);
  line-height: em(26,14);
  margin-bottom: em(20,14);

  @include respond($pc) {
    border-top-width: 2px;
    display: table;
    margin-bottom: em(40,14);
    width: 100%;
  }

  & + .form-table {
    border-top: 0;
    margin-top: em(40,14);

    @include respond($pc) {
      border-top: 2px solid $grey-dash;
    }
  }
}
.table-head-inner {
  @include respond($pc) {
    width: em(250,14);
  }
}
input, textarea, select {
  font-size: 16px !important;
}
select {
  height: 26px;
}