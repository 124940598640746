a, a:visited {
	// color: #000;
  color: inherit;
	text-decoration: none;
}
a:hover, a:focus {
	color: $blue-light;
}
a:active {
	color: $blue-light;
}

a.simple, a.simple:link, a.simple:visited {
  color: $blue-light;
}
.anchor-list {
  background: $blue-bg;
  // margin-bottom: 20px;
  margin-bottom: 40px;
  padding: 20px;

  .link-list > li {
    margin-bottom: 20px;

    @include respond($pc) {
      margin-bottom: 4px;
    }
  }
  .link-list > li:last-child {
    margin-bottom: 0px;
  }

  &.white {
    background: #fff;
    padding: 0;

    .link-list {
      line-height: em(22,13);

      @include respond($pc) {
        line-height: em(34,14);
      }
    }
  }
  &.bordered {
    border: 1px solid #ddd;
    padding: 20px;
  }
  &.margined {
    margin-bottom: 30px;

    @include respond($pc) {
      margin-bottom: 40px;
    }
  }

  @include respond($pc) {
    margin-bottom: 30px;
    padding: 20px 30px;
  }

  .grid, .grid.pc, .link-list {
    margin-bottom: 0;
  }

  .grid2:last-child, .grid2:nth-child(2n+1):nth-last-child(2) {
    // margin-bottom: 20px;
  }
}
.back-link {
  font-size: em(16);
  // line-height: em(30, 16);

  &::before {
    border: em(5) solid transparent;
    border-right-color: $blue-light;
    content: " ";
    display: inline-block;
    margin-right: em(5);
    vertical-align: middle;
  }
}
.back-link-wrap {
  margin: em(50) 0;
}
.button-link {
  border: 1px solid $grey-border;
  display: inline-block;
  height: 1em;
  padding: 12px;
  vertical-align: middle;

  &.orange {
    border-color: $orange;
  }
  &.blue {
    border-color: $blue-light;
  }
  &.grey {
    background-color: $blue-bg;
    text-align: center;
  }
  &.full {
    display: block;
  }

  &.semi {
    @include respond($pc) {
      border: 0;
      padding: 0 12px;
    }
  }
}
.caret-link {
  display: inline-block;
  padding-left: 10px;
  position: relative;

  &::before {
    border: em(6) solid transparent;
    border-left-color: $blue-light;
    content: " ";
    display: inline-block;
    margin-left: -10px;
    vertical-align: middle;
  }
  &.down {
    padding-left: 16px;
    text-decoration: none;

    &::before {
      border: em(6) solid transparent;
      border-top-color: $blue-light;
      margin: 3px 2px 0 -16px;
    }
  }
  &.flying {
    overflow: hidden;
    padding: 0 20px;

    span {
      display: inline-block;
      transform: translateX(0px);
      transition: color 0.2s 0s ease-out, transform 0.2s 0.1s ease-out, -webkit-transform 0.2s 0.1s ease-out;
    }
  }
  &.flying::before {
    border-width: em(6) !important;
    opacity: 1;
    transition: opacity 0.2s 0s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.2s 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.2s 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    transform: translateX(0px);
  }
  &.flying::after {
    border: em(6) solid transparent;
    border-left-color: #fff;
    content: " ";
    display: inline-block;
    margin-left: -5px;
    vertical-align: middle;

    opacity: 0;
    transition: opacity 0.2s 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.2s 0s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.2s 0s cubic-bezier(0.645, 0.045, 0.355, 1);
    transform: translateX(40px);
  }
  *:hover > &.flying {
    @include respond($dt) {
      span {
        transform: translateX(-20px);
      }
      &::before {
        opacity: 0;
        transform: translateX(-40px);
      }
      &::after {
        opacity: 1;
        transform: translateX(0px);
      }
    }
  }
}
a.caret-link {
  text-decoration: underline;
}
a.free-link {
  text-decoration: none;
  
  @include respond($pc) {
    font-size: em(14);
  }
}
a.icon-title-link {
  font-size: em(15,13);
  margin-bottom: 10px;
  text-decoration: none;

  @include respond($pc) {
    font-size: em(20,14);
  }

  .icon {
    margin-right: 10px;
  }
}
.link-list {
  font-size: em(13);
  line-height: em(20,13);
  margin-bottom: em(30,13);

  @include respond($pc) {
    font-size: em(14);
    line-height: em(34,14);
    margin-bottom: em(40,14);
  }

  &.big {
    font-size: em(14);
    line-height: em(22,14);

    @include respond($pc) {
      font-size: em(16);
      line-height: em(36,16);
      margin-bottom: em(40,16);
    }
  }
  &.tiled {
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
    -ms-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    overflow: hidden;

    a {
      background: $grey-nav;
      border-bottom: 1px solid #fff;
      box-sizing: border-box;
      display: block;
      height: 100%;
      line-height: em(18,13);
      padding: em(20,13) em(15,13);
      // width: 100%;

      &:hover {
        background: transparentize($grey-nav, .4);
      }
    }
    .caret-link {
      padding-left: em(25,13);
      text-decoration: none;
    }

    & > li {
      width: 100%;

      @include respond($pc) {
        box-sizing: border-box;
        float: left;
        width: 50%;

        &:nth-child(2n) {
          border-left: 1px solid #fff;
        }
      }
    }
  }

  .text-box & {
    font-size: 1em;
    line-height: inherit;
    margin: 15px 0 0;

    @include respond($pc) {
      margin-top: 20px;
    }
  }
  &.disc {
    list-style-type: disc;
    padding-left: 15px;

    & > li {
      display: list-item;
    }
  }
  & > li {
    display: block;

    &.indented {
      padding-left: 15px;
    }
  }
}
.panel-link {
  background: rgba(255,255,255,.85);
  border: 1px solid #fff;
  border-radius: 3px;
  bottom: 0;
  box-sizing: border-box;
  font-size: em(15);
  height: em(50, 15);
  left: 0;
  margin: auto;
  padding: 10px;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: em(197,15);

  &:link, &:visited {
    color: $blue-light;
  }

  @include respond($pc) {
    font-size: em(20);
    height: em(80, 20);
    padding: 20px;
    width: em(300, 20);

    &.small, &.smallest {
      height: em(60, 20);
    }
  }
  &.animated, .anim-wrap {
    // border: 0;
    border-radius: 0;

    &::before, &::after {
      border-left: 1px solid #fff;
      content: " ";
      display: block;
      height: 0%;
      position: absolute;
      width: 0;

      -webkit-transition: height .3s ease-in, width .3s ease-in, opacity .3s ease-in;
      transition: height .3s ease-in, width .3s ease-in, opacity .3s ease-in;
    }
    &::before {
      left: 0;
      bottom: 0;
    }
    &::after {
      right: 0;
      top: 0;
    }

    .panel-link-wrap:hover & {
      @include respond($dt) {
        border: 0;
        color: #fff;

        &::before, &::after {
          height: 100%;
        }
      }
    }
  }
  .anim-wrap {
    // position: relative;
    padding-top: 7px;

    @include respond($pc) {
      padding-top: 0;
    }

    &::before, &::after {
      border-top: 1px solid #fff;
      height: 0;
      width: 0%;
    }
    &::before {
      bottom: auto;
      top: 0;
    }
    &::after {
      bottom: 0;
      top: auto;
    }
    .panel-link-wrap:hover & {
      @include respond($pc) {
        &::before, &::after {
          height: 0;
          width: 100%;
        }
      }
    }
  }
  .interactive & {
    overflow: hidden;
    // -webkit-transition: all .2s ease-out;
    // transition: all .2s ease-out;
    -webkit-transition: background-color .2s ease-out, color .2s ease-out, height .2s ease-out, width .2s ease-out, opacity .2s ease-out;
    transition: background-color .2s ease-out, color .2s ease-out, height .2s ease-out, width .2s ease-out, opacity .2s ease-out;

    .panel-link-help {
      font-size: em(14, 20);
      line-height: em(22,14);
      margin: em(20, 14) auto 0;
      max-width: em(410, 14);
      opacity: 0;

      -webkit-transition: background-color .2s ease-out, color .2s ease-out, height .2s ease-out, width .2s ease-out, opacity .2s ease-out;
      transition: background-color .2s ease-out, color .2s ease-out, height .2s ease-out, width .2s ease-out, opacity .2s ease-out;
    }
  }
  .interactive:hover & {

    @include respond($dt) {
      background: rgba(255,255,255,0);
      color: $blue-light;
      height: em(160, 20);
      width: em(440, 20);

      &.small {
        height: em(140, 20);
      }
      &.smallest {
        height: em(120, 20);
      }
      .panel-link-help {
        opacity: 1;
      }
      .caret-link, small {
        -webkit-transition: all .2s ease-out;
        transition: all .2s ease-out;
        color: #fff;
      }
    }
  }

  &.transparent {
    background-color: rgba(0,0,0,.15);
    color: #fff;

    .caret-link, small {
      color: #fff;
      margin-top: 7px;

      @include respond($pc) {
        margin-top: 0;
      }
    }

    &.big {
      @include respond($pc) {
        width: em(320);
      }
    }
  }
  .caret-link, small {
    color: #000;
    font-size: em(8, 15);
    line-height: em(8,8);
    margin-top: 5px;

    @include respond($pc) {
      font-size: em(12, 20);
      margin-top: 10px;
    }

    &::before {
      border-width: 4px;
      vertical-align: middle;
    }
  }
}
.sns-link {
  display: inline-block;
  letter-spacing: -100px;
  margin-right: 10px;
  overflow: hidden;
}
.text-title-link {
  display: block;
  font-size: em(13, 13);
  // line-height: 1em;
  line-height: em(18,13);
  margin-bottom: em(10, 13);

  @include respond($pc) {
    font-size: em(20, 14);
    line-height: em(26, 20);
    margin-bottom: em(10, 20);
  }
}
