hr {
  border: 0;
  border-bottom: 2px solid $grey-underline;
  clear: both;
  margin: 0 15px 15px;
  padding-top: 15px;
}

.block-center {
  display: inline-block;
  text-align: left;

  &.sp {
    @include respond($pc) {
      display: block;
    }
  }
}
.label-blue {
  background-color: $blue-light;
  color: #fff;
  display: inline-block;
  padding: 0 5px;

  &.padded {
    border-radius: 2px;
    line-height: 1em;
    margin-bottom: 10px;
    padding: 5px 12px;

    @include respond($pc) {
      margin-bottom: 10px;
      padding: 3px 15px;
    }
  }
}
.nowrap {
  white-space: nowrap;

  &.pc {
    white-space: normal;

    @include respond($pc) {
      white-space: nowrap;
    }
  }
}
.reading {
  font-size: em(13);
  line-height: em(18,13);
  margin-bottom: em(13,13);

  @include respond($pc) {
    font-size: em(14);
    line-height: em(26, 14);
    margin-bottom: em(8,14);
    // margin-bottom: em(28,14);
  }

  &.figured {
    line-height: em(23,13);
    margin-bottom: em(30,13);
    overflow: hidden;

    @include respond($pc) {
      line-height: em(24,14);
      margin-bottom: em(40,14);
    }

    figure img {
      display: block;
      margin: 0 auto;
    }
  }
  &.zoomable {
    text-align: center;

    @include respond($pc) {
      text-align: left;
    }

    .clear {
      display: block;
      text-align: left;
    }
  }
  &.small {

    @include respond($pc) {
      font-size: em(12);
      line-height: em(22, 12);
    }
  }
}
figcaption.reading {
  line-height: em(23,13);

  @include respond($pc) {
    line-height: em(24,14);
  }
}
.reading-block {
  border: 2px solid #e8edf0;
  padding: 20px;

  & > :last-child {
    margin-bottom: 0;
  }
}
.text-big {
  font-size: em(15);
  line-height: em(25,15);
  margin-bottom: em(20,15);

  @include respond($pc) {
    font-size: em(16);
    line-height: em(26,16);
    margin-bottom: em(40,16);
  }
}
.text-blue {
  color: $blue-over;

  &:link, &:visited {
    color: $blue-over;
  }
}
.text-bold {
  font-weight: bold;
}
.text-box {
  border: 1px solid $grey-underline;
  font-size: em(13);
  line-height: em(23,13);
  margin-bottom: em(15,13);
  padding: em(15,13);

  @include respond($pc) {
    font-size: em(14);
    line-height: em(24,14);
    margin-bottom: em(40,14);
    padding: em(20,14);
  }

  &.fat {
    border-width: 3px;
  }
  .text-title-link {
    font-size: em(16,13);
    line-height: em(23,16);
    margin-bottom: em(15,16);

    @include respond($pc) {
      font-size: em(20,14);
      line-height: em(24,20);
      margin-bottom: em(15,20);
    } 

    .caret-link {
      display: block;
    }
  }
  &.thick {
    border-width: 2px;
  }
  .grid {
    margin-bottom: 0;
  }
  .list {
    font-size: 1em;
    line-height: inherit;
    margin-bottom: 0;
  }
}
.text-box-grid {
  & + .text-box-grid {
    margin-top: -15px;

    @include respond($pc) {
      margin-top: 0;
    }
  }
  &.grid {
    @include respond($pc) {
      margin-bottom: em(30);
    }
  }
}
.text-box-image {
  display: block;
  margin-bottom: 10px;
  text-align: center;

  @include respond($pc) {
    margin-bottom: 20px;
  }
}
.text-break {
  word-break: break-all;
}
.text-center {
  text-align: center;
}
.text-grey {
  color: rgba(0,0,0,.6);
}
.text-left {
  text-align: left;
}
.text-light {
  color: $grey-hover;
}
.text-middle {
  display: inline-block;
  vertical-align: middle;
}
.text-normal {
  font-weight: normal;
}
.text-red {
  color: $red-over;
}
.text-right {
  text-align: right;
}
.text-large {
  font-size: em(15);
  line-height: em(25,15);
  margin-bottom: em(20,15);

  @include respond($pc) {
    font-size: em(16);
    line-height: em(26,16);
    margin-bottom: em(40,16);
  }
}
.text-med {
  margin-bottom: em(20,13);

  @include respond($pc) {
    margin-bottom: em(40,14);
  }
}
.text-small {
  font-size: em(10);
  line-height: em(15,10);
  margin-bottom: em(20,10);

  @include respond($pc) {
    font-size: em(12);
    line-height: em(22,12);
    margin-bottom: em(40,12);
  }
}
.text-title-link {
  font-size: em(16);
  line-height: em(23, 16);
  margin-bottom: em(15, 16);

  @include respond($pc) {
    font-size: em(20);
    line-height: em(24,20);
    margin-bottom: em(15, 20);
  } 
}