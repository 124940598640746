.info-display-item {
  position: relative;

  @include respond($pc) {
    float: left;
    width: 50%;
  }
  & > img {
    display: block;
    width: 100%;
  }

  .panel-link {
    border: 1px solid #fff;
    bottom: 75px;

    @include respond($dt) {
      bottom: 115px;
    }
  }
  &:hover .panel-link {
    @include respond($dt) {
      border-width: 0;
      padding: 30px 15px;
    }
  }
  &::before {
    background: $blue-over;
    bottom: 0;
    content: " ";
    display: block;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;

    -webkit-transition: opacity .2s ease-in;
    transition: opacity .2s ease-in;
  }
  &:hover::before {
    @include respond($pc) {
      opacity: .7;
    }
  }
}
.info-display-link {
  background: #fff;
  box-sizing: border-box;
  display: block;
  height: 72px;
  padding: 10px 0;
  text-align: center;
  width: 100%;

  @include respond($pc) {
    &:hover {
      opacity: .7;
    }
  }

  @include respond($dt) {
    height: 105px;
    padding: 20px 0;
  }

  .text-box & {
    background: $grey-nav;
  }
}
.info-display-link-inner {
  display: table-cell;
  height: 33px;
  padding: 0 5px;
  vertical-align: middle;
}
.info-display-link-item {
  box-sizing: border-box;
  float: left;  
  padding: 0 1px;
  position: relative;

  .three & {
    width: 33.33333%;
  }
  .four & {
    width: 25%;
  }

  .text-box & {
    border-left: 1px solid #fff;
    padding: 0;

    &:first-child {
      border-left: 0;
    }
  }
}
.info-display-link-list {
  bottom: 10px;
  left: 15px;
  margin: 0 -5px;
  position: absolute;
  right: 15px;

  .text-box & {
    margin: 15px 0 0;
    overflow: hidden;
    position: static;

    @include respond($pc) {
      margin-top: 20px;
    }
  }
}
.info-display-list {
  overflow: hidden;
}
.info-display-text-inner {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}
.info-display-link-text {
  bottom: 10px;
  box-sizing: border-box;
  display: table;
  font-size: em(10);
  left: 0;
  line-height: em(12,10);
  margin-top: 5px;
  position: absolute;
  width: 100%;

  @include respond($dt) {
    font-size: em(14);
    height: em(30,14);
    line-height: em(15,14);
  }

  .caret-link {
    text-decoration: none;

    &::before {
      border-width: 4px;
      bottom: 0;
      left: 0;
      height: 0;
      margin: auto;
      position: absolute;
      top: 0;
    }
  }

  .text-box & {
    font-size: em(10,13);

    @include respond($pc) {
      font-size: em(14,14);
    }
  }
}