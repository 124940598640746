.news-body {
  display: block;
  margin-top: 10px;

  @include respond($pc) {
    display: inline-block;
    vertical-align: top;
    width: calc(100% - 16.5em);

    .no-tags & {
      width: calc(100% - 7.5em);
    }
  }
}
.news-buttons {
  font-size: em(13);
  margin-bottom: 30px;
  text-align: center;

  @include respond($pc) {
    float: right;
    font-size: em(14);
    margin-bottom: 0;
    margin-top: -10px;
  }
}
.news-date {
  display: inline-block;
  padding-right: 15px;
  padding-top: 10px;

  .big & {
    @include respond($pc) {
      padding: 10px em(10,13) 0;
    }
  }
}
.news-item {
  border-top: 1px dashed $grey-dash;
  font-size: em(13);
  line-height: em(18, 13);
  // padding: em(15, 13) 0;

  &:last-child {
    border-bottom: 1px dashed $grey-dash;

    @include respond($pc) {
      border: 0;

      .big & {
        border-bottom: 1px dashed $grey-dash;
      }
    }
  }

  @include respond($pc) {
    border: 0;
    font-size: em(14);
    line-height: em(24,14);

    .big & {
      border-top: 1px dashed $grey-dash;
    }
    // padding: em(5,13) 0;
  }
}
.news-link {
  display: block;
  padding: 0 0 em(10,13);

  // @include respond($pc) {
  //   position: relative;
  // }
}
a:hover {
  .news-body {
    color: $blue-light;
    // opacity: .5;
  }
  .news-date {
    color: $blue-light;
  }
  .news-tag {
    background-color: $blue-light;
  }

  @include respond($pc) {
    .big & {
      background-color: $grey-nav;
      color: $grey-hover;
    }
  }
}
.news-pickup {
  // margin: 0 -5px;
  // overflow: hidden;

  @include respond($pc) {
    position: absolute;
    right: 0;
    top: 0;
  }
}
.news-pickup-caption {
  background: #fff;
  bottom: 0;
  font-size: em(9);
  left: 0;
  line-height: em(12,9);
  padding: 5px 10px;
  position: absolute;
  right: 0;

  @include respond($pc) {
    font-size: em(12);
    line-height: em(16,12);
  }

  .caret-link {
    position: relative;

    &::before {
      border-width: 4px;
      bottom: 0;
      height: 0;
      left: 0;
      margin: auto;
      position: absolute;
      top: 0;

      @include respond($pc) {
        border-width: 6px;
      }
    }
  }
}
.news-pickup-fig {
  border: 1px solid $grey-border;
  position: relative;
}
.news-pickup-item {
  box-sizing: border-box;
  float: right;
  padding: 0 5px;
  width: 50%;

  @include respond($pc) {
    float: none;
    margin-bottom: 10px;
    width: auto;
  }
}
.news-pickup-list {

  @include respond($pc) {
    margin-bottom: 5px;
    width: 240px;
  }
}
.news-tag {
  background: $grey-tag;
  border-radius: 3px;
  color: #fff;
  display: inline-block;
  font-size: em(12, 14);
  // line-height: em(18, 11);
  line-height: 1em;
  margin-right: em(6, 12);
  padding: 3px 0;
  text-align: center;
  width: em(55,12);

  @include respond($pc) {
    margin-right: 15px;
  }

  .big & {
    @include respond($pc) {
      margin: 0 em(20,12) 0 em(10,12);
      width: em(70,12);
    }
  }
}
.news-title {
  margin: 50px 0 20px;
  text-align: center;

  @include respond($pc) {
    border-bottom: 2px solid $blue-light;
    font-size: em(18);
    line-height: em(30,18);
    margin: 7px 0 16px;
    padding-bottom: 8px;
  }
}
.news-wrap {

  &.with-pickup {
    @include respond($pc) {
      padding-right: 270px;
      position: relative;
    }
  }
}
.pagination {
  font-size: em(13);
  margin-bottom: em(30,13);
  position: relative;
  text-align: center;
}
.pagination-inner {
  display: inline-block;
  overflow: hidden;
  text-align: center;
}
.pagination-list {
  // display: inline-block;
  // white-space: nowrap;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}
.pagination-arrow {
  display: inline-block;
  height: 42px;
  line-height: 42px;
  vertical-align: middle;

  &.prev {
    float: left;

    &::before {
      border: em(4,13) solid transparent;
      border-right-color: $blue-light;
      content: " ";
      display: inline-block;
      margin-right: em(8,13);
      vertical-align: middle;
    }
  }
  &.next {
    float: right;

    &::after {
      border: em(4,13) solid transparent;
      border-left-color: $blue-light;
      content: " ";
      display: inline-block;
      margin-left: em(8,13);
      vertical-align: middle;
    }
  }
}
.pagination-item {
  display: inline-block;
  margin-bottom: 10px;
  padding: 0 5px;
}
.pagination-link {
  border: 1px solid $grey-underline;
  display: block;
  height: em(40,13);
  line-height: em(42,13);
  vertical-align: middle;
  width: em(40,13);

  &:hover, &.current {
    background-color: $grey-nav;
  }
}
.pickup-link {
  @include respond($pc) {
    margin-top: -10px;
  }
}