.timeline {
  position: relative;
  
  &::after {
    bottom: 0;
    clear: both;
    content: "";
    display: block;
    height: 30px;
    position: absolute;
    width: 100%;

    background: rgba(255,255,255,0);
    background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255,255,255,0)), color-stop(100%, rgba(255,255,255,1)));
    background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
    background: -o-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
    background: -ms-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
    background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff', GradientType=0 );
  }
}
.timeline-date {
  box-sizing: border-box;
  clear: left;
  color: $blue-light;
  font-size: em(15);
  left: 0;
  line-height: 1em;
  position: absolute;
  text-align: center;
  width: 75px;

  @include respond($pc) {
    font-size: em(18);
    width: 105px;
  }

  &::after {
    background: $blue-light;
    border: 4px solid $blue-light;
    border-radius: 20px;
    bottom: 0;
    content: "";
    display: block;
    height: 1px;
    margin: auto;
    position: absolute;
    right: -6px;
    top: 0;
    width: 1px;
    z-index: 1;

    @include respond($pc) {
      border-width: 5px;
      right: -7px;
    }
  }
}
.timeline-event {
  border-left: 3px solid $grey-underline;
  box-sizing: border-box;
  display: block;
  font-size: em(13);
  line-height: em(18,13);
  padding: 0 0 25px 16px;
  position: relative;

  @include respond($pc) {
    font-size: em(14);
    line-height: em(24,14);
    margin-top: -2px;
    padding: 0 0 40px 20px;
  }

  img {
    display: block;
    margin: 10px 0 10px;
  }

  .timeline-item:first-child &::before {
    background: #fff;
    content: "";
    display: block;
    height: 6px;
    left: -4px;
    position: absolute;
    width: 4px;
  }
}
.timeline-item {
  padding-left: 75px;
  position: relative;

  @include respond($pc) {
    padding-left: 105px;
  }
}