.clearfix::after, .grid::after, .flex-list::after {
   content: " ";
   visibility: hidden;
   display: block;
   height: 0;
   clear: both;
}
.clear {
  clear: both;

  &.sp {
    clear: both;

    @include respond($pc) {
      clear: none;
    }
  }
  &.pc {
    clear: none;

    @include respond($pc) {
      clear: both;
    }
  }
}