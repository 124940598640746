.side-content {

  @include respond($pc) {
    box-sizing: border-box;
    float: left;
    padding-right: em(40);
    width: (960 - 232) / 960 * 100%;
  }

  .notitle-content & {
    & > h1:first-child, & > h2:first-child, & > .content-group:first-child > h1:first-child, & > .content-group:first-child > h2:first-child {
      margin-top: 0;
    }
  }
}
.side-nav {
  border: 1px solid $grey-underline;
  box-sizing: border-box;
  // margin-top: em(30);
  
  @include respond($pc) {
    float: right;
    // margin-top: em(60);
    width: 232 / 960 * 100%;

    &.shiftup {
      margin-top: -46px;
    }
  }

  .notitle-content & {
    margin-top: 0;
  }
  
}
.side-nav-foot-banner {
  margin-top: 10px;
  text-align: center;

  @include respond($pc) {
    clear: right;
    float: right;
    text-align: left;
    width: 232 / 960 * 100%;  
  }
}
.side-nav-foot-link {
  display: block;
  font-size: em(13);
  padding: 5px 15px;

  @include respond($pc) {
    box-sizing: border-box;
    font-size: em(11);
    padding: 5px 0 5px 10px;
  }
}
.side-nav-item {
  border-bottom: 1px solid $grey-underline;

  &:last-child {
    border-bottom: 0;
  }

  &.indented {
    // padding-left: 10px;
    .side-nav-link {
      padding-left: 40px;
    }
  }
  &.current {
    background: $grey-nav-light;
  }
}
.side-nav-link {
  display: block;
  font-size: em(13);
  padding: 15px 20px 15px 30px;

  @include respond($pc) {
    font-size: em(14);
  }

  &:hover {
    color: $blue-light;
  }
}
a.side-nav-link {
  text-decoration: none;
}
.side-nav-title {
  border-bottom: 2px solid $blue-light;
  border-left: 0;
  font-size: em(14);
  line-height: 1em;
  margin: 0;
  padding: 15px 20px;
  text-align: left;

  @include respond($pc) {
    font-size: em(18);
  }

  a, a:link, a:visited {
    color: $blue-light;
  }
}