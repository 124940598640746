
.container {
  margin: 0 auto;
  max-width: em(960);
  padding: 0 em(15);

  &.narrow {
    max-width: em(760);
  }
}
.content-group {
  margin-bottom: em(30);

  @include respond($pc) {
    margin-bottom: em(60);
  }
}
.flex-list {
  & > li {
    width: auto;
  }

  @include respond($pc) {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0 -10px;

    & > li {
      box-sizing: border-box;
      margin: 0 10px 20px;
      width: 50%;
      width: -webkit-calc(50% - 20px);
      width: calc(50% - 20px);
    }
  }
}
.grid {
  margin: 0 -10px 20px;

  &.flex {
    @include respond($pc) {
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex; 
    }
  }

  &.center {
    text-align: center;

    .grid2 {
      display: inline-block;
      float: none;
    }
  }

  &.pc {
    margin: 0 0 30px;
  }

  &, &.pc {
    @include respond($pc) {
      margin: 0 -10px 40px;
    }
  }

  .grid-list & {

    @include respond($pc) {
      margin-bottom: em(20);
    }
  }
}
.grid2 {
  box-sizing: border-box;
  float: left;
  padding: 0 em(10);
  width: 50%;

  .self-clearing &:nth-child(2n+1) {
    clear: left;
  }

  @include respond($pc) {
    .flex & > .text-box {
      box-sizing: border-box;
      float: none !important;
      height: 100%;
    }
  }

  .right & {
    float: right;
  }
  .right.self-clearing &:nth-child(2n+1) {
    clear: right;
  }
  .pc & {
    float: none;
    padding: 0;
    width: auto;
  }

  &, .pc & {
    @include respond($pc) {
      float: left;
      padding: 0 10px;
      width: 50%;

      :last-child {
        margin-bottom: 0;
      }
    }
  }
}
.grid3 {
  box-sizing: border-box;

  @include respond($pc) {
    float: left;
    padding: 0 em(15);
    width: 33.33333%;
  }
}
.grid-img {
  @include respond($pc) {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}
.fleft {
  float: left;
}
.fright {
  float: right;
}
.mb-s {
  margin-bottom: 15px;
}
.notitle-content {
  margin-bottom: em(30);
  margin-top: em(30);

  @include respond($pc) {
    margin-bottom: em(60);
    margin-top: em(60);
  }
}
.text-container {
  margin: 0 auto;
  max-width: em(760);
}
.width-50px {
  width: 50px;
}
.width-70px {
  width: 70px;
}
.width-100px {
  width: 100px;
}
.width-140px {
  width: 140px;
}
.width-200px {
  width: 200px;
}
.ie-only {
  display: none;
}