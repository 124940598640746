// h1 {
//   color: $blue-over;
//   font-size: em(20);
//   font-weight: normal;
//   line-height: em(20,20);
//   margin-bottom: em(18,20);

//   @include respond($pc) {
//     font-size: em(30);
//     line-height: em(34,30);
//     margin-bottom: em(40,30);
//   }
// }
h1 {
  border: 0;
  font-size: em(20);
  font-weight: bold;
  line-height: em(20,20);
  // margin: 0;
  margin-bottom: em(18,20);
  // text-align: left;

  @include respond($pc) {
    font-size: em(32);
    line-height: em(34, 32);
    margin-bottom: em(40,30);
  }
}
h2 {
  font-size: em(17);
  font-weight: normal;
  line-height: em(17,17);
  margin-bottom: em(20,17);
  padding-bottom: em(10,17);
  border-bottom: 2px solid $blue-light;

  @include respond($pc) {
    font-size: em(28);
    line-height: em(34, 28);
    margin: em(50,28) 0 em(34,28);
    padding-bottom: 6px;
  }

  .label-blue + & {
    margin-top: 0;
  }
}
h3 {
  border-left: 2px solid $blue-light;
  font-size: em(17);
  font-weight: normal;
  line-height: em(21,17);
  margin-bottom: em(10,17);
  margin-top: em(20,17);
  padding-left: em(6,17);

  @include respond($pc) {
    border-left: 3px solid $blue-light;
    font-size: em(24);
    line-height: em(34,24);
    margin-bottom: em(10,24);
    // margin-top: em(0,24);
    padding-left: em(15,24);
  }

  &.big {
    @include respond($pc) {
      border-left: 4px solid $blue-light;
      font-size: em(24);
      line-height: em(34, 24);
      margin: em(30,24) 0 em(20,24);
      padding-left: em(16,24);
    }
  }
  &.med {
    border-left: 0;
    padding-left: 0;

    @include respond($pc) {
      font-size: em(20);
      line-height: em(34, 20);
      margin: em(30,20) 0 em(20,20);
    }
  }
  &.small {
    border-left: 0;
    padding-left: 0;

    @include respond($pc) {
      border-bottom: 2px solid $grey-header;
      font-size: em(16);
      line-height: em(34, 16);
      margin: em(30,16) 0 em(20,16);
    }
  } 
}
h4 {
  color: $blue-over;
  font-size: em(17);
  font-weight: normal;
  line-height: em(17,17);
  margin-bottom: em(10,17);
  // margin-top: em(20,17);

  @include respond($pc) {
    font-size: em(24);
    line-height: em(30,24);
    margin-bottom: em(10,24);
    // margin-top: em(20,17);
  }
}
h5 {
  border-bottom: 1px solid $grey-underline;
  font-size: em(15);
  font-weight: normal;
  line-height: em(17,15);
  margin-bottom: em(13,15);
  padding-bottom: em(8,15);

  @include respond($pc) {
    font-size: em(20);
    line-height: em(30,20);
    padding-bottom: em(0,20);
    margin-bottom: em(20,20);
  }

  &.no-line {
    border-bottom: 0;
    padding-bottom: 0;
  }
}
h6 {
  font-size: em(15);
  font-weight: bold;
  line-height: em(17,15);
  // margin-bottom: em(13,15);
  margin-bottom: em(5,15);

  @include respond($pc) {
    font-size: em(20);
    line-height: em(30,20);
    // margin-bottom: em(12,20);
    margin-bottom: em(5,20);
  }
}

.region-title {
  border: 0;
  color: $blue-light;
  font-size: em(20);
  font-weight: normal;
  line-height: em(24, 20);
  margin: em(50,20) 0 em(25,20);
  padding-bottom: 0;
  text-align: center;

  @include respond($pc) {
    font-size: em(30);
    line-height: em(34, 30);
    margin: em(60,30) 0 em(40,30);
  }

  &.small {
    text-align: left;

    @include respond($pc) {
      font-size: em(22);
      line-height: em(30, 22);
      margin-bottom: em(16,22);
    }
  }
}
.sitemap-title {
  border-bottom: 0;
  display: inline-block;
  font-size: em(18);
  line-height: 1em;
  margin: 0 0 20px;

  @include respond($pc) {
    font-size: em(24);
  }

  a {
    text-decoration: underline;
  }
}