.breadcrumb-item {
  display: inline;
  font-size: 12px;
  line-height: em(20, 12);

  &::after {
    content: "＞";
    display: inline-block;
    padding: 0 em(9, 12);
  }
  &:last-child::after {
    display: none;
  }
}
.breadcrumb-link {

  &, &:link, &:visited {
    color: $grey-breadcrumb;
  }
}
.breadcrumb-wrap {
  // background: $grey-nav;
  padding: 0 0 em(30);

  // &[data-bg="filelist"] {
  //   background: url("/filelist/static/images/banner_sp.png") no-repeat;
  //   background-position: center;
  //   background-size: 100% 150px;

  //   @include respond($pc) {
  //     background-image: url("/filelist/static/images/banner_pc.png");
  //     background-size: 100% 200px;
  //   }
  // }
  &[data-bg] {
    background-position: right;
    background-repeat: no-repeat;
    background-size: auto 150px;
    box-sizing: border-box;
    height: 150px;

    @include respond($pc) {
      background-size: auto 200px;
      height: 200px;
    }
  }
  &[data-bg="filelist"] {
    background-color: #BFE0EF;
    background-image: url("/filelist/static/images/banner_pc.png");
    // background-position: right;
    // background-size: auto 150px;
    // box-sizing: border-box;
    // height: 150px;

    // @include respond($pc) {
    //   background-size: auto 200px;
    //   height: 200px;
    // }
  }
  &[data-bg="business"] {
    background-color: #dee8ee;
    background-image: url("/common/images/banner/banner-business-sp.png");
    
    @include respond($pc) {
      background-image: url("/common/images/banner/banner-business.png");
    }
  }
  &[data-bg="contact"] {
    background-color: #e5f1fb;
    background-image: url("/common/images/banner/banner-contact-sp.png");
    
    @include respond($pc) {
      background-image: url("/common/images/banner/banner-contact.png");
    }
    // background-position: right;
    // background-size: auto 150px;
    // box-sizing: border-box;
    // height: 150px;

    // @include respond($pc) {
    //   background-size: auto 200px;
    //   height: 200px;
    // }
  }
  &[data-bg="corporate"] {
    background-color: #8bc3ee;
    background-image: url("/common/images/banner/banner-corporate-sp.png");
    // background-position: right;
    // background-size: auto 150px;
    // box-sizing: border-box;
    // height: 150px;
    @include respond($pc) {
      background-image: url("/common/images/banner/banner-corporate.png");
    }
    // @include respond($pc) {
    //   background-size: auto 200px;
    //   height: 200px;
    // }
  }
  &[data-bg="csr"] {
    background-color: #a5daf1;
    background-image: url("/common/images/banner/banner-csr-sp.png");
    
    @include respond($pc) {
      background-image: url("/common/images/banner/banner-csr.png");
    }
  }
  &[data-bg="ir"] {
    background-color: #bfdcef;
    background-image: url("/common/images/banner/banner-ir-sp.png");
    
    @include respond($pc) {
      background-image: url("/common/images/banner/banner-ir.png");
    }
  }
  &[data-bg="news"] {
    background-color: #eaf4fd;
    background-image: url("/common/images/banner/banner-news-sp.png");
    
    @include respond($pc) {
      background-image: url("/common/images/banner/banner-news.png");
    }
  }
}
.breadcrumb-list {
  font-size: 0;
  margin-bottom: 30px;
  padding-top: 20px;

  &.free {
    margin-bottom: 30px;

    @include respond($pc) {
      margin-top: 15px;
      margin-bottom: 50px;
    }

    li {
      display: none;

      @include respond($pc) {
        display: inline-block;
      }
    }
  }
}
// .breadcrumb-title {
//   // color: $blue-light;
//   color: #000;
//   border: 0;
//   font-weight: bold;
//   margin: 0;
//   text-align: left;

//   @include respond($pc) {
//     font-size: em(32);
//     line-height: em(34, 32);
//   }
// }