img {
  max-width: 100%;
}
// figure {
//   margin-bottom: em(30);
//   text-align: center;

//   @include respond($pc) {
//     margin-bottom: em(40);
//   }

//   img {
//     margin-bottom: em(15);

//     @include respond($pc) {
//       margin-bottom: em(20);
//     }
//   }

//   &.tight {
//     img {
//       margin-bottom: 0px;
//     }
//     figcaption {
//       font-size: em(10);
//       line-height: em(15,10);

//       @include respond($pc) {
//         font-size: em(12);
//         line-height: em(22,12);
//       }
//     }
//   }
// }
// figcaption {
//   text-align: left;
// }
figure {
  // margin-bottom: em(30);
  margin-bottom: em(10);
  text-align: center;

  .grid2 & {
    margin-bottom: em(20);
  }

  @include respond($pc) {
    // margin-bottom: em(40);
    margin-bottom: em(10);

    .grid2 & {
      margin-bottom: em(40);
    }
  }

  img {
    // margin-bottom: em(15);

    // @include respond($pc) {
    //   margin-bottom: em(20);
    // }
  }

  &.tight {
    img {
      margin-bottom: 0px;
    }
    
  }
}
.tight-caption {
  display: inline-block;
  font-size: em(10);
  line-height: em(15,10);

  @include respond($pc) {
    font-size: em(12);
    line-height: em(22,12);
  }
}
figcaption {
  text-align: left;

  .reading {
    clear: both;
    display: block;
    margin-top: em(15);

    @include respond($pc) {
      margin-top: em(20);
    }
  }
  .grid2 & {
    overflow: hidden;

    .image-zoom-tag {
      margin-bottom: 0px;
    }
  }
}
.cert {
  border: 1px solid #ddd;
  margin-right: 10px;
  padding: 5px 15px;

  @include respond($pc) {
    margin-right: 15px;
    padding: 15px 40px;
  }
}
.fixed-fig {

  @include respond($pc) {
    padding-left: 310px;
    position: relative;
    text-align: left;
  }

  &.bordered {
    border: 1px solid #ddd;
    padding: 10px;

    @include respond($pc) {
      min-height: 160px;
      padding: 10px 10px 10px 320px;

      .fixed-fig-image {
        top: 10px;
        left: 10px;
      }
    }
  }
}
.fixed-fig-caption {
  text-align: left;

  @include respond($pc) {

  }
}
.fixed-fig-image {
  @include respond($pc) {
    left: 0;
    position: absolute;
    top: 0;
  }
}
.floatfig {
  width: 50%;
  width: calc(50% - 10px);

  @include respond($pc) {
    width: calc(50% - 15px);
  }

  &.left {
    float: left;
    margin: 0 15px 15px 0;

    @include respond($pc) {
      margin: 0 30px 15px 0;
    }
  }
  &.right {
    float: right;
    margin: 0 0 15px 15px;

    @include respond($pc) {
      margin: 0 0 15px 30px;
    }
  }
  &.pc {
    float: none;
    margin: 0 auto 15px;
    width: auto;

    @include respond($pc) {
      width: calc(50% - 15px);

      &.right {
        float: right;
        margin: 0 0 30px 30px;
      }
      &.left {
        float: left;
        margin: 0 30px 30px 0;
      }
    }
  }
  &.big {
    @include respond($pc) {
      margin-bottom: 0;
    }
  }
}
.image-zoom-tag {
  background: $grey-nav;
  float: right;
  margin: 5px 0 20px;
  padding: 2px 4px;

  @include respond($pc) {
    display: none;
  }

  .icon-search {
    height: 14px;
    margin-right: 2px;
    width: 15px;
  }
}
// .image-zoom-tag {
//   background: $grey-nav;
//   float: right;
//   margin: -10px 0 20px;
//   padding: 2px 4px;

//   .zoomable & {

//   }

//   @include respond($pc) {
//     display: none;
//   }

//   .icon-search {
//     height: 14px;
//     margin-right: 2px;
//     width: 15px;
//   }
// }
.map {
  padding-bottom: 75%;
  position: relative;

  iframe {
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }
}
.map-wrap {
  margin-bottom: 20px;
}
// .image-link {
//   display: inline-block;

//   &::after {
//     content: 
//   }
// }
.zoom-image {
  display: block;
  overflow: hidden;

  @include respond($pc) {
    overflow: visible;
  }
}