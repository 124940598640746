.movie-banner {
  bottom: 0;
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 1;

  &.ie-only {
    display: none;
  }
}
video.movie-banner {
  opacity: 0;
  z-index: 2;
}
.movie-banner-wrap {
  margin-bottom: em(30);
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  width: 100%;

  @include respond($pc) {
    margin-bottom: em(60);
    // padding-bottom: 29.41176%;
    // padding-bottom: 33.33333%;
    padding-bottom: 33.33333%;
  }

  .panel-link {
    border-radius: 0;
    bottom: 25px;
    top: auto;
    z-index: 5;

    @include respond($pc) {
      bottom: 40px;
      font-size: 1em;
      height: 3.75em;
      width: em(280);

      -webkit-transition: background-color .2s ease-in;
      transition: background-color .2s ease-in;

      &:hover {
        background-color: transparentize($blue-dark, .2);
        border: 1px solid transparentize($blue-dark, .2);

        .caret-link::before {
          border-left-color: #fff;
        }
      }
    }
  }
  .caret-link {
    font-size: em(13);

    @include respond($pc) {
      font-size: em(18);

      &::before {
        border-width: 5px;
      }
    }
  }
}
.random-vid {
  & > li {
    display: none;

    &.playing {
      display: block;
    }
  }
}
.random-img {

  & > li {
    // display: none;

    img {
      -webkit-transition: -webkit-transform 3s linear, opacity 1.5s linear;
      transition: transform 3s linear, opacity 1.5s linear;
    }
    &.last img {
      opacity: 1;
      z-index: 3;

      -webkit-transform: translate3d(0px, 0px, 0px) scale(1.1);
      transform: translate3d(0px, 0px, 0px) scale(1.1);
    }
    &.on-deck img {
      opacity: 0;
      z-index: 2;
    }
    &.from-left img {
      -webkit-transform: translate3d(-10px, 0px, 0px) scale(1.1);
      transform: translate3d(-10px, 0px, 0px) scale(1.1);
    }
    &.from-right img {
      -webkit-transform: translate3d(10px, 0px, 0px) scale(1.1);
      transform: translate3d(10px, 0px, 0px) scale(1.1);
    }
    &.zoom-to img {
      -webkit-transform: translate3d(0px, 0px, 0px) scale(1);
      transform: translate3d(0px, 0px, 0px) scale(1);
    }
    // &.from-top img {
    //   -webkit-transform: translate3d(0px, -10px, 0px) scale(1.1);
    //   transform: translate3d(0px, -10px, 0px) scale(1.1);
    // }
    &.from-bottom img {
      -webkit-transform: translate3d(0px, 10px, 0px) scale(1.1);
      transform: translate3d(0px, 10px, 0px) scale(1.1);
    }
      
    &.current img {
      opacity: 1;
      -webkit-transform: translate3d(0px, 0px, 0px) scale(1.1);
      transform: translate3d(0px, 0px, 0px) scale(1.1);
      z-index: 4;
    }

  }
}
