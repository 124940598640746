.search-input, .header-nav-search input.gsc-input, .header-search input.gsc-input {
  border-radius: 3px;
  box-sizing: border-box;
  display: block;
  line-height: em(13,13);
  padding: em(15,13);
  width: 100%;
}
.header-nav-search {
  input.gsc-input {
    background: transparent !important;
    border: 0;
    // color: #fff;
  }
  .gsc-input-box {
    background: $grey-nav !important;
  }
}
.search-input-wrap {
  display: block;
  position: relative;

  &::after {
    background: url("/common/images/icon/icon-search.png") no-repeat;
    background-size: cover;
    bottom: 0;
    content: " ";
    display: block;
    height: 17.5px;
    margin: auto;
    position: absolute;
    right: em(15);
    top: 0;
    width: 17.5px;
  }
  .gsc-search-button, .gsc-clear-button {
    display: none;
  }
  .gscb_a, .gsib_b {
    display: none;
  }
  input.gsc-input {
    height: 36px !important;
  }
  .gsc-input-box {
    height: 40px !important;
  }
  td.gsc-input {
    padding-right: 0 !important;
  }
  .gsc-search-box {
    margin-bottom: 0 !important;
  }
  * {
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
}