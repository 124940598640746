.link-grid-content {
  display: block;
}
.link-grid-img-wrap {
  display: block;
  height: em(80);
  line-height: em(80);

  .no-caption & {
    height: em(50);
    line-height: em(50);
  }

  img {
    max-width: 80%;
    vertical-align: middle;
  }

  .loose & {
    height: em(55);
    line-height: em(55);

    @include respond($pc) {
      height: em(75);
      line-height: em(75);
    }

    img {
      max-height: 70%;

      @include respond($pc) {
        max-height: 100%;
      }
    }
  }
  .small & {
    height: em(48);
    line-height: em(48);
  }
}
.link-grid-item {
  border-left: 1px solid $grey-header;
  box-sizing: border-box;
  float: left;

  [data-size="3"] & {
    border-top: 1px solid $grey-header;
    width: 33.33333%;

    &:nth-child(3n) {
      border-right: 1px solid $grey-header;

      .loose & {
        border-right: 0;
      }
    }

    &:nth-child(1), &:nth-child(2), &:nth-child(3) {
      border-top: 0;
    }

    @include respond($pc) {
      border-top: 0;
      width: 16.66666%;  

      &:nth-child(3n) {
        border-right: 0;
      }
      &:last-child {
        border-right: 1px solid $grey-header;

        .loose & {
          border-right: 0;
        }
      }
    }
  }
  [data-size="4"] & {
    border-bottom: 1px solid $grey-header;
    width: 50%;

    &:nth-child(2n) {
      border-right: 1px solid $grey-header;

      .loose & {
        border-right: 0;
      }
    }
    &:last-child, &:nth-last-child(2) {
      border-bottom: 0;
    }

    @include respond($pc) {
      width: 25%;

      &:nth-child(2n) {
        border-right: 0;
      }
      &:last-child {
        border-right: 1px solid $grey-header;

        .loose & {
          border-right: 0;
        }
      }
    }
  }

  @include respond($pc) {
    // display: inline-block;
    // float: none;

    // [data-size] & {
    //   width: auto;
    // }
  }

  &:last-child {
    border-right: 1px solid $grey-header;
  }

  .loose & {
    border: 0;
    padding: 5px;

    .link-grid-link {
      border: 1px solid $grey-header;

    }
  }
}
.link-grid-link {
  box-sizing: border-box;
  display: block;

  @include respond($pc) {
    height: em(135);
    padding: em(10) 0;
    
    // [data-size="3"] & {
    //   width: 320px;
    // }
    // [data-size="4"] & {
    //   width: 240px;  
    // }

    .no-caption & {
      height: em(70);
    }
    .loose & {
      height: em(95);
      padding: em(10) 0;
    }
    .small & {
      height: em(70);
    }
    .big & {
      height: em(115);
      padding: em(20) 0;
    }
  }
}
a.link-grid-link {
  &:hover {
    opacity: .7;
  }
}
.link-grid-list {
  overflow: hidden;
  
  text-align: center;

  @include respond($pc) {
    height: em(135);
    

    &.no-caption {
      height: em(70);
    }
  }
  .loose & {
    height: auto;
    margin: 0 -5px -5px;

    @include respond($pc) {
      margin-bottom: 0;
    }
  }
}
.link-grid-text {
  color: $blue-light;
  margin-bottom: 1em;
}
.link-grid-wrap {
  border-bottom: 1px solid $grey-header;
  border-top: 1px solid $grey-header;
  margin-bottom: em(55);

  @include respond($pc) {
    margin-bottom: em(60);
  }

  // & + .link-grid-wrap {
  //   border-top: 0;
  //   margin-top: em(-50);

  //   @include respond($pc) {
  //     margin-top: em(-60);
  //   }
  // }
  &.loose {
    border: 0;
  }
}
